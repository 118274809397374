import React from 'react';
import "./sass/index.scss";
import App from './ts/App';
import ReactDOM from "react-dom";

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('app')
);

