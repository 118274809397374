import ConfettiCanvas from 'react-confetti-canvas';

const App = () => {
    return (
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-sky-400 to-blue-500">
            <ConfettiCanvas
                paperCount={250}
                ribbonCount={15}
            />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <h1 className="text-5xl font-bold text-center text-white">Gefeliciteerd Niek!</h1>
                <p className="text-2xl text-center text-white">Klik op het cadeautje hieronder om je cadeau te ontdekken!</p>
                <div className="flex justify-center mt-20 animate-bounce">
                    <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank" rel="noreferrer">
                        <img className='w-44' src="https://static.vecteezy.com/system/resources/previews/001/198/620/original/gift-png.png" alt="cadeautje" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default App;